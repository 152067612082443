import Cookies from 'js-cookie';
import Config from '@/setting';
const config = Config;
const domain = document.domain

export function setCookie(key,data,isRemember) {
  let obj;
  if(data instanceof Object || data instanceof Array){
    obj = encodeURIComponent(JSON.stringify(data));
  }else{
    obj = String(data);
  }
  if (isRemember) {
    // Cookies.set(key, process.env.NODE_ENV !== 'development'? encrypt(obj, config.userPublicKey):obj, { expires: config.tokenCookieExpires })
    Cookies.set(key, obj, { expires: Config.tokenCookieExpires,  domain: domain} );
  } else
  // Cookies.set(key, process.env.NODE_ENV !== 'development'? encrypt(obj, config.userPublicKey):obj)
    Cookies.set(key, obj, {  domain: domain});
}
export function getCookie(key) {
  // let data = process.env.NODE_ENV !== 'development'? decrypt(Cookies.get(key), config.userPrivateKey):Cookies.get(key)
  let data = Cookies.get(key);
  return data;
}
export function removeCookie(key, resolve) {
  if(key){
    Cookies.remove(key, {domain: domain});
  }else{
    Cookies.remove({domain: domain});
  }
  if(resolve){
    resolve();
  }
}


export function setLocalStorage(key,data) {
  let obj;
  if(data instanceof Object || data instanceof Array){
    obj = encodeURIComponent(JSON.stringify(data));
  }else{
    obj = String(data);
  }
  localStorage.setItem(key, obj);
}
export function getLocalStorage(key) {
  return localStorage.getItem(key);
}
export function removeLocalStorage(key) {
  if(key) {
    localStorage.removeItem(key);
  }else{
    localStorage.clear();
  }
}

export function getOrgList() {

}
// export function getOrgList() {
//   return request({
//     url: `${service}/account/getOrgList`,
//     method: 'get'
//   });
// }