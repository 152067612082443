import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '@/utils/set';
import Config from '@/setting'

export const constantRouterMap = [
    {
        path: '/default',
        hidden: true,
        component: (resolve) => require(['@/components/HelloWorld.vue'], resolve),
    },
    {
        path: '/',
        redirect:'/home'
    },
    {
        path: '/home',
        name: 'home',
        hidden: true,
        component: () => import('@/views/home.vue')
    },
    {
        path: '/intro',
        name: 'intro',
        hidden: true,
        component: () => import('@/views/intro.vue')
    },
    {
        path: '/scheme',
        name: 'scheme',
        hidden: true,
        component: () => import('@/views/scheme.vue')
    },
    {
        path: '/about',
        name: 'about',
        hidden: true,
        component: () => import('@/views/about.vue')
    },
]
const router = createRouter({
    base: '/',
    history: createWebHistory(),
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
})
router.beforeEach((to, from, next) => {
    const whiteList = ['/login']
    // const isLogin = getCookie(Config.token) ? true : false;
    const isLogin = true ;
    if (isLogin) {
        if (to.path == '/login' || to.path == '/') {
            next('/home')
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1 || to.path == '/forget') {
            next()
        } else {
            next('/login')
        }
    }
});
export default router