import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn';
import '@/styles/reset.css';
import '@/styles/common.less';
import '@/styles/custom.less';
import '@/styles/layout.less';
import App from './App.vue'
// import "lib-flexible"
import router from '@/router'

const app = createApp(App)

app.use(ElementPlus, {locale})
app.use(router)
app.mount('#app')
